import * as React from "react"
import { CallToAction } from "../callToAction"
import { headingSplitter, blockToContent } from "../../helpers"
import { getImageUrl } from "../../utils/getImageUrl"

export function Card({ cta, hero }) {
  const heroContentPosition =
    hero.headerPosition === "end"
      ? "right"
      : hero.headerPosition === "start"
      ? "left"
      : "center"
  return (
    <>
      <div
        className={`relative ${
          hero.contentPosition === "below" ? "hero" : ""
        } bg-white overflow-hidden`}
      >
        <div className="max-w-6xl mx-auto">
          <div className="sm:px-4 hero_block">
            <div className="relative sm:mt-6 md:mt-8 lg:mt-10 xl:mt-14">
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
              <div className="max-w-8xl mx-auto">
                <div className="relative sm:shadow-xl sm:rounded-md sm:overflow-hidden">
                  <div
                    className="absolute inset-0 hero_bg"
                    style={{
                      backgroundImage: `url(${getImageUrl(
                        hero.bgimage?.asset?._ref
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute inset-0 bg-gray-500/30 mix-blend-multiply" />
                  </div>

                  <div
                    className={`relative px-4 hero_content py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 flex flex-col items_${
                      hero.headerPosition || "center"
                    }`}
                  >
                    <h1
                      className={`text-4xl font-extrabold text-white text_shadow tracking-tight sm:text-5xl lg:text-6xl text-${heroContentPosition}`}
                    >
                      {headingSplitter(hero?.heading)}
                    </h1>
                    <p
                      className={`mt-6 max-w-lg text-lg font-bold text_shadow text-white sm:max-w-3xl text-${heroContentPosition}`}
                    >
                      {hero?.subheading &&
                        blockToContent(hero.subheading[0]?.children)}
                    </p>
                    <div className="mt-10 max-w-sm sm:max-w-none sm:flex hero_button sm:justify-center">
                      <CallToAction {...cta} hero={hero} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
